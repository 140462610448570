import { computed } from 'vue';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

export const useCookie = (key?: string) => {
  const cookie = computed(() => {
    /* eslint-disable-next-line */
    const value = cookies.get(key ?? '');

    return value ? String(value) : null;
  });

  return { cookie };
};
